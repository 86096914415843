import {
	toQueryString,
	convertDataQueryLinksToHtmlAnchors,
	getImpliedLink,
	getImpliedLinks,
	getPostSignupUrl,
	resolveDocumentLink,
	resolveEmailLink,
	resolvePhoneLink,
	resolveAddressLink,
	resolveDynamicPageLink,
	resolveWhatsAppLink,
	getBaseUrlFilesPath,
	hasLinkDataWithCurrentRouteAnchors,
} from '@wix/link-utils'

import { overrideSuffix, queryParamsWhitelist as defaultQueryParamsWhitelist } from './constants/queryParamsWhitelist'

const keepInternalQueryParamsOnly = (
	searchParams: URLSearchParams,
	queryParamsWhitelist: Set<string> = defaultQueryParamsWhitelist
) => {
	const entries = searchParams.entries()
	const filteredEntries = [...entries].filter(
		([key]) => queryParamsWhitelist.has(key) || key.endsWith(overrideSuffix)
	)

	return new URLSearchParams(filteredEntries).toString()
}

export const appendInternalQueryParamsToHref = (href: string, currentUrl: URL) => {
	const nextUrl = new URL(href, currentUrl.origin)
	const nextUrlSearch = nextUrl.search
	nextUrl.search = keepInternalQueryParamsOnly(currentUrl.searchParams)

	new URLSearchParams(nextUrlSearch).forEach((val, key) => {
		nextUrl.searchParams.set(key, val)
	})

	return nextUrl.toString()
}

export {
	toQueryString,
	convertDataQueryLinksToHtmlAnchors,
	getImpliedLink,
	getImpliedLinks,
	getPostSignupUrl,
	resolveDocumentLink,
	resolveEmailLink,
	resolvePhoneLink,
	resolveAddressLink,
	resolveDynamicPageLink,
	resolveWhatsAppLink,
	getBaseUrlFilesPath,
	hasLinkDataWithCurrentRouteAnchors,
}
