export const ALL_LANGUAGE_CODES = {
	ar: 'ar',
	bg: 'bg',
	ca: 'ca',
	zh: 'zh',
	cs: 'cs',
	da: 'da',
	nl: 'nl',
	fi: 'fi',
	fr: 'fr',
	de: 'de',
	el: 'el',
	he: 'he',
	hi: 'hi',
	hu: 'hu',
	id: 'id',
	it: 'it',
	ja: 'ja',
	jp: 'jp',
	ko: 'ko',
	kr: 'kr',
	lt: 'lt',
	ms: 'ms',
	no: 'no',
	pl: 'pl',
	pt: 'pt',
	ro: 'ro',
	ru: 'ru',
	es: 'es',
	sk: 'sk',
	sl: 'sl',
	sv: 'sv',
	tl: 'tl',
	th: 'th',
	tr: 'tr',
	uk: 'uk',
	vi: 'vi',
	hr: 'hr',
	lv: 'lv',
	en: 'en',
} as const

export const RTL_LANGUAGES = [
	'ar',
	'arc',
	'bcc',
	'bqi',
	'ckb',
	'dv',
	'fa',
	'glk',
	'ha',
	'he',
	'khw',
	'ks',
	'ku',
	'mzn',
	'pnb',
	'ps',
	'sd',
	'ug',
	'ur',
	'yi',
]
