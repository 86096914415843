import { RTL_LANGUAGES } from './constants'
import type { Language, LanguageDirection } from '@wix/thunderbolt-becky-types'

export const isRTL = (language: Language) => RTL_LANGUAGES.includes(extractLanguageCode(language))

export const getDirection = (language: Language): LanguageDirection =>
	isRTL(language) ? ('rtl' as const) : ('ltr' as const)

const extractLanguageCode = (language: Language) => {
	return language && language.split('-')[0]
}
