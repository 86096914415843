import type { Component } from '@wix/thunderbolt-becky-types'

const SHARED_PART_COMP_ID_TO_INFLATED_ID: { [key: string]: string } = {}
const SHARED_PART_INFLATED_ID_TO_COMP_ID: { [key: string]: string } = {}

export const GHOST_COMP_TYPE = 'GhostComp'

export const REF_DELIMITER = '_r_'

export const SHARED_PARTS_PREFIX = 'sharedParts'

export const getUniqueId = (refId: string, originalId: string) => `${refId}${REF_DELIMITER}${originalId}`

export const removeSharedPartsPrefix = (id: string) => id.replace(`${SHARED_PARTS_PREFIX}${REF_DELIMITER}`, '')

export const fixSharedPartsIds = (id: string) => `${SHARED_PARTS_PREFIX}${REF_DELIMITER}${id}`

export const hasSharedPartsPrefix = (id: string) =>
	id.split(REF_DELIMITER)[0] === SHARED_PARTS_PREFIX && id.split(REF_DELIMITER).length >= 2

export function replaceSharedPartsPrefix(str: string, replacement: string) {
	return str.replace(SHARED_PARTS_PREFIX, replacement)
}

export const getRefCompIdFromInflatedId = (id: string): string => {
	if (hasSharedPartsPrefix(id)) {
		return id
	}

	return id.split(`${REF_DELIMITER}`)[0]
}

// Will give different result than getRefCompIdFromInflatedId()
// in case of widget-in-widget. for example:
// id='firstRefComp_r_secondRefComp_r_innerCompId' -> return 'firstRefComp_r_secondRefComp'
export const getMultipleRefCompsIdFromInflatedId = (id: string): string => {
	const isSharedPart = hasSharedPartsPrefix(id)
	const refCompId = isSharedPart ? removeSharedPartsPrefix(id) : id
	const multipleRefCompsIdFromInflatedId = refCompId.substring(0, refCompId.lastIndexOf(`${REF_DELIMITER}`)) || id

	return isSharedPart ? fixSharedPartsIds(multipleRefCompsIdFromInflatedId) : multipleRefCompsIdFromInflatedId
}

export const getTemplateFromInflatedId = (id: string): string =>
	removeSharedPartsPrefix(id).split(REF_DELIMITER).pop() as string

export const getRefCompIdsFromInflatedId = (id: string): Array<string> =>
	getMultipleRefCompsIdFromInflatedId(id).split(`${REF_DELIMITER}`)

export const isRefComponent = (component?: Component) => component?.componentType.endsWith('RefComponent')

export function isInflatedId(id: string): boolean {
	return removeSharedPartsPrefix(id).includes(REF_DELIMITER)
}

export function saveInflatedIdToMaps(compId: string, inflatedId: string) {
	const previousInflatedId = SHARED_PART_COMP_ID_TO_INFLATED_ID[compId]

	if (compId === inflatedId || previousInflatedId === inflatedId) {
		return
	}

	if (previousInflatedId) {
		delete SHARED_PART_INFLATED_ID_TO_COMP_ID[previousInflatedId]
	}

	SHARED_PART_COMP_ID_TO_INFLATED_ID[compId] = inflatedId
	SHARED_PART_INFLATED_ID_TO_COMP_ID[inflatedId] = compId
}

export const deflateSharedPartCompId = (compId: string): string => {
	return SHARED_PART_INFLATED_ID_TO_COMP_ID[compId] || compId
}

export const deflateMultiSharedPartCompIds = (compIds: Array<string>) =>
	compIds?.map((compId) => deflateSharedPartCompId(compId))

export const inflateSharedPartCompId = (compId: string): string => {
	return SHARED_PART_COMP_ID_TO_INFLATED_ID[compId] || compId
}

export const inflateMultiSharedPartCompIds = (compIds: Array<string>) =>
	compIds?.map((compId) => inflateSharedPartCompId(compId))

export const getInflatedIdsKeys = (refId: string) => {
	return Object.keys(SHARED_PART_INFLATED_ID_TO_COMP_ID).filter((compId: string) => compId.endsWith(refId))
}

export const hasFakeInflation = (compId: string) => SHARED_PART_COMP_ID_TO_INFLATED_ID.hasOwnProperty(compId)

export const getInflatedIdAncestorChain = (itemId: string) => {
	const inflatedIdAncestorChain: Array<string> = []
	itemId
		.split(REF_DELIMITER)
		.reverse()
		.forEach((item, index) =>
			index === 0
				? inflatedIdAncestorChain.push(item)
				: inflatedIdAncestorChain.push(getUniqueId(item, inflatedIdAncestorChain[index - 1]))
		)
	return inflatedIdAncestorChain.reverse()
}
