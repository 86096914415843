import { queryParamsWhitelist } from './constants/queryParamsWhitelist'

export const applyRetainedQueryParams = (browserCurrentUrl: URL, targetHref: string): URL => {
	const urlWithProtocol = targetHref.startsWith('//') ? `${browserCurrentUrl.protocol}${targetHref}` : targetHref
	const targetUrl = new URL(urlWithProtocol)

	if (browserCurrentUrl.host === targetUrl.host) {
		const siteQueryParams = browserCurrentUrl.searchParams
		siteQueryParams.forEach((value, key) => {
			if (queryParamsWhitelist.has(key) && !targetUrl.searchParams.has(key)) {
				targetUrl.searchParams.set(key, value)
			}
		})
	}

	return targetUrl
}
