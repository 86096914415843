import { fontsMetadata } from '@wix/fonts-data'

export function getFontFamilyWithFallbacks(fontFamily: string): string {
	if (!fontFamily) {
		return ''
	}

	const cleanFontName = fontFamily.replace(/\+/g, ' ').toLowerCase()
	const [highestPriorityFont] = cleanFontName.split(',')
	const metaData = fontsMetadata[highestPriorityFont]
	if (!metaData) {
		return fontFamily
	}
	return [highestPriorityFont, metaData.fallbacks, metaData.genericFamily].filter((t) => t).join(',')
}
