declare global {
	interface Scheduler {
		yield?: () => Promise<void>
	}

	// eslint-disable-next-line no-var
	var scheduler: Scheduler | undefined
	// eslint-disable-next-line no-var
	var isUseNativeSchedulerYieldExperimentOpen: string | boolean | undefined
}

// break long task to smaller tasks, see here: https://web.dev/optimize-long-tasks/#use-asyncawait-to-create-yield-points
export const yieldToMain = () => {
	if (
		process.env.browser &&
		process.env.PACKAGE_NAME !== 'thunderbolt-ds' &&
		process.env.RENDERER_BUILD !== 'react-native'
	) {
		if (globalThis.isUseNativeSchedulerYieldExperimentOpen && globalThis.scheduler?.yield) {
			return globalThis.scheduler.yield()
		}

		return new Promise((resolve) => setTimeout(resolve, 0))
	}
}

export const taskify = async <T>(task: () => T) => {
	await yieldToMain()
	return task()
}
