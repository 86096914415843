import type { FedopsLogger } from '@wix/fe-essentials-viewer-platform/fedops'
import type { ILogger } from '@wix/thunderbolt-symbols'

export const reportSiteMemberIdWithoutSmToken = (
	logger: FedopsLogger | ILogger | undefined,
	siteMemberId: string | undefined,
	smToken: string | undefined
) => {
	if (siteMemberId && !smToken) {
		logger?.interactionStarted('siteMemberId_is_defined_while_smToken_is_not_defined')
	}
}
